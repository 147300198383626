import LoginLayout from '@components/pages/login/LoginLayout';
import Login from '@components/pages/login/Login';
import { useEffect } from 'react';
import { routeLayout } from 'src/paths';
import AuthService from '@services/AuthService';
import { useRouter } from 'next/router';

export default function LoginPage() {
  const router = useRouter();

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      router.push(routeLayout.home);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Login />;
}

LoginPage.getLayout = function getLayout(page) {
  return <LoginLayout>{page}</LoginLayout>;
};
