import { useQuery } from 'react-query';
import AuthService from '../../../services/AuthService';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Checkbox } from '@mui/material';
import * as S from 'src/components/pages/login/Login.styled.js';
import { useState } from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SelectRoles({ selectedRoles, register }) {
  const [isServerRoles, setIsServerRoles] = useState(true);
  const [serverRoles, setServerRoles] = useState([]);

  useQuery(['roles', isServerRoles], AuthService.getRoles, {
    enabled: isServerRoles,
    onSuccess: onSuccessRoles,
    onError: onErrorRoles,
  });

  function onSuccessRoles(response) {
    setServerRoles(response.data);
    setIsServerRoles(false);
  }

  function onErrorRoles(response) {
    console.error(response.status, response.statusText);
    setIsServerRoles(false);
  }

  return (
    <S.SelectRoles
      multiple
      id="tags-outlined"
      size="small"
      options={serverRoles}
      disableCloseOnSelect
      getOptionLabel={(option) => option}
      renderOption={(props, option, { selected }) => {
        if (selected) {
          if (!selectedRoles.includes(option)) {
            selectedRoles.push(option);
          }
        }
        if (selectedRoles.includes(option) && !selected) {
          const index = selectedRoles.indexOf(option);
          selectedRoles.splice(index, 1);
        }

        const keyProp = props.key;
        delete props.key;
        return (
          <li key={keyProp} {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        );
      }}
      renderInput={(params) => (
        <S.TextField
          {...params}
          {...register('Roles')}
          // disabled={isLoading}
          label="Roles"
          placeholder="Select roles"
        />
      )}
    />
  );
}
