import {
  Box,
  Button as NSButton,
  FormControl as FControl,
  Input as NSInput,
  styled,
  Switch,
  Typography,
  Autocomplete,
  TextField as MUITextField,
} from '@mui/material';
import NSLink from 'src/components/common/Link';

/* LoginLayout */
export const PageWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const Main = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.blue,
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  minHeight: 420,
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.common.white,
  },
}));

export const Form = styled(Box)(({ theme, borderRadius }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.white,
  borderRadius: borderRadius ?? theme.shape.classic.borderRadius,
  boxShadow: theme.shadows[1],
  display: 'flex',
  flexDirection: 'column',
  padding: '40px 20px',
  maxWidth: '100%',
  width: 550,
  [theme.breakpoints.down('sm')]: {
    boxShadow: 'none',
  },
}));

export const Title = styled(Box)(({ marginBottom }) => ({
  marginBottom: marginBottom ?? 20,
  marginTop: 30,
}));

/* login */

export const InputArea = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 140;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const Input = styled(NSInput)(({ theme }) => ({
  height: 54,
  margin: `0px 0px ${theme.spacing(2.5)} 0px`,
  border: 'none',
  maxWidth: '90vw',
  width: 400,
  [theme.breakpoints.down('sm')]: {
    fontSize: 14,
  },
}));

export const SubmitArea = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    height: 80,
  },
}));

export const FormControl = styled(FControl)(() => ({
  margin: '1rem 0',
  maxWidth: 400,
}));

export const Link = styled(NSLink)(({ theme }) => ({
  color: theme.palette.common.blue,
  fontFamily: theme.typography.secondary,
  fontSize: 14,
  fontWeight: 400,
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.ease,
    duration: theme.transitions.duration.standard,
  }),
  '&:hover': {
    color: 'rgba(0, 0, 0, 0.9)',
  },
}));

export const Button = styled(NSButton)(
  ({ theme, bradius, margin, width, fontWeight }) => ({
    textTransform: 'unset',
    margin: margin ?? '1rem 0',
    borderRadius: bradius ?? 5,
    width: width ?? '',
    fontWeight: fontWeight ?? '',
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.ease,
      duration: theme.transitions.duration.standard,
    }),
  })
);

export const TextLoginLayout = styled(Typography)(({ theme }) => ({
  marginTop: 20,
  fontSize: 30,
  color: theme.palette.primary.light,
  display: 'flex',
  justifyContent: 'center',
}));

export const SelectEnv = styled(Switch)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
}));

export const SelectRoles = styled(Autocomplete)(() => ({}));
export const TextField = styled(MUITextField)(() => ({}));
