import Image from 'next/image';

export const ArsatLogo = ({ height, width }) => {
  return (
    <Image
      src="/images/arsat_logo-min.svg"
      height={height ?? 20}
      width={width ?? 118}
      alt="ARSAT"
      priority
    />
  );
};
